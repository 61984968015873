import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getYoutubeSliceContent = createAsyncThunk('/youtubes/content', async () => {
    var userData=JSON.parse(localStorage.getItem("userData"));
	const response = await axios.post(`${API_URL}/getYoutube`, {});
	return response;
})

export const youtubeSlice = createSlice({
    name: 'youtubes',
    initialState: {
        isLoading: false,
        youtubes : []
    },
    reducers: {
    },
    extraReducers: {
		[getYoutubeSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getYoutubeSliceContent.fulfilled]: (state, action) => {
			state.youtubes = action.payload.data
			state.isLoading = false
		},
		[getYoutubeSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default youtubeSlice.reducer