import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getAboutTextSliceContent = createAsyncThunk('/aboutTexts/content', async () => {
	const response = await axios.post(`${API_URL}/getAboutText`);
    console.log(response.data);
	return response;
})

export const abouteTextSlice = createSlice({
    name: 'aboutTexts',
    initialState: {
        isLoading: false,
        aboutTexts : []
    },
    reducers: {
    },
    extraReducers: {
		[getAboutTextSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getAboutTextSliceContent.fulfilled]: (state, action) => {
			state.aboutTexts = action.payload.data
			state.isLoading = false
		},
		[getAboutTextSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default abouteTextSlice.reducer