import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getGallerySliceContent = createAsyncThunk('/gallery/content', async () => {
    var userData=JSON.parse(localStorage.getItem("userData"));
	const response = await axios.post(`${API_URL}/getGalleryImages`, {});  
    return response;
})

export const gallerySlice = createSlice({
    name: 'gallery',
    initialState: {
        isLoading: false,
        gallery : []
    },
    reducers: {
    },
    extraReducers: {
		[getGallerySliceContent.pending]: state => {
			state.isLoading = true
		},
		[getGallerySliceContent.fulfilled]: (state, action) => {
			state.gallery = action.payload.data
			state.isLoading = false
		},
		[getGallerySliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default gallerySlice.reducer