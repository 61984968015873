import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getRecentActivitySliceContent = createAsyncThunk('/recentactivity/content', async () => {
    var userData=JSON.parse(localStorage.getItem("userData"));
	const response = await axios.post(`${API_URL}/getRecentActivity`, {});  
    return response;
})

export const recentactivitySlice = createSlice({
    name: 'recentactivity',
    initialState: {
        isLoading: false,
        recentactivity : []
    },
    reducers: {
    },
    extraReducers: {
		[getRecentActivitySliceContent.pending]: state => {
			state.isLoading = true
		},
		[getRecentActivitySliceContent.fulfilled]: (state, action) => {
			state.recentactivity = action.payload.data
			state.isLoading = false
		},
		[getRecentActivitySliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default recentactivitySlice.reducer