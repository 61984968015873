import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getContactMetaSliceContent = createAsyncThunk('/contactMetas/content', async () => {
	const response = await axios.post(`${API_URL}/getContactUsMetaData`);
    console.log(response.data);
	return response;
})

export const contactMetaSlice = createSlice({
    name: 'contactMetas',
    initialState: {
        isLoading: false,
        contactMetas : []
    },
    reducers: {
    },
    extraReducers: {
		[getContactMetaSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getContactMetaSliceContent.fulfilled]: (state, action) => {
			state.contactMetas = action.payload.data
			state.isLoading = false
		},
		[getContactMetaSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default contactMetaSlice.reducer